<template>
  <div class='container'>
    <div class="row">
      <div class="col-sm bg-primary text-white rounded d-block d-sm-none plakat">
        <img src="../assets/Silvester-web.jpg" alt="Silvester Plakat" />
      </div>
      <div class="col-sm-8 bg-primary text-white rounded info-text">
        <div class="color-overlay">
          <p>Wir laden ein zu der großen SILVESTERPARTY!</p>
          <p>
            <b>LIVE: Gents of Jazz</b>
            <br>(Coverssongs mit einem gewissen Charme)<br>
            <b>&amp; DISCO </b>mit unseren Haus DJs<b><br></b>
          </p>
          <iframe type="text/html"
                  src="https://www.youtube.com/embed/xNvqi7oWVBc?controls=0&disablekb=1&modestbranding=1&iv_load_policy=3"
                  title="Gents of Jazz Promo" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <p>
            Im Eintrittspreis enthalten:
            <ul><li>ein Fingerfood-Buffet</li><li>ein Begrüßungsgetränk</li></ul>
            Außerdem im Angebot:
            <ul><li>Kleiner Imbiss</li><li>Glühwein am Feuer</li></ul>
          </p>
          <p>
            <b>Kartenpreis im Vorverkauf: 32€</b>
          </p>
        </div>
      </div>
      <div v-if="false" class="col-sm-4 bg-primary text-white rounded">
        <p class="pt-3">
          Aktuell gibt es leider Probleme mit unserem Bestellformular, wir arbeiten aber dran und hoffen, dass es bald wieder für euch zur Verfügung steht.
        </p>
        <p>
          Bitte schreibt uns an <b>silvester(AT)kasseturm.de</b> eine Email mit Namen, Adresse und die Anzahl der Karten, die ihr haben wollt und wir melden uns alsbald zurück.
        </p>
      </div>
      <div v-if="true" class="col-sm-4 bg-primary text-white rounded">
          <form @submit.prevent="submit" :action="'https://www.form-to-email.com/api/s/' + prodID" method="POST">
              <div class="form-group mt-2">
                <label for="inputNumber">Karten</label>
                <input type="number" class="form-control mr-2" id="inputNumber" value="1" name="anzahl" required>
              </div>
              <div class="form-group">
                  <label for="inputEmail4">Email</label>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="name@adresse.de" name="email" required>
              </div>
              <div class="form-group">
                  <label for="inputName">Name</label>
                  <input type="text" class="form-control" id="inputName" placeholder="Vor- & Nachname" name="name" required>
              </div>
              <div class="form-group">
                <label for="inputAddress">Adresse</label>
                <input type="text" class="form-control" id="inputAddress" placeholder="Straße + Hausnummer" name="address" required>
              </div>
              <div class="form-group">
                  <label for="inputZip">PLZ</label>
                  <input type="text" class="form-control" id="inputZip" placeholder="11111" name="plz" required>
              </div>
              <div class="form-group">
              <label for="inputCity">Stadt</label>
              <input type="text" class="form-control" id="inputCity" placeholder="Stadt" name="city" required>
              </div>
          <!--    
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck">
                  <label class="form-check-label" for="gridCheck">
                    Check me out
                  </label>
                </div>
              </div>
          -->
              <div class="form-group mt-4">
                  <button type="submit" class="btn btn-primary btn-block send-button">Karten bestellen</button>
              </div>
        </form>
      </div>
    </div>
    <div>
      <b-modal ref="modal-erfolg" ok-only title="Bestellung versandt" @ok="closeModal">
        <p class="my-4">Vielen Dank für die Bestellung der Karten</p>
        <p>Wir melden uns alsbald mit dem weiteren Vorgehen zurück.</p>
      </b-modal>
      <b-modal ref="modal-fehler" ok-only title="Es tut uns leid" @ok="closeModal">
        <p class="my-4">Leider ist etwas schief gelaufen!</p>
        <p>Bitte versuchen sie es erneut oder schreiben uns eine E-Mail an <b>silvester(AT)kasseturm.de</b></p>
      </b-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Watch } from "vue-property-decorator";

@Component({})
export default class Infos extends Vue {
  private prodID = 'QhTKHp5OmTb8';
  private testID = '_7rjaPdWnh4C';

  mounted() {
    if(this.$route.params.modal == 'erfolg') {
        //@ts-ignore
        (this.$refs['modal-erfolg'] as HTMLDivElement).show();
      }else if(this.$route.params.modal == 'fehler') {
        //@ts-ignore
        (this.$refs['modal-fehler'] as HTMLDivElement).show();
      }
  }

  @Watch("$route.params")
    paramsChanged(newParams: { [key: string]: string }) {
        console.log(newParams.modal);
        if(newParams.modal == 'erfolg') {
          //@ts-ignore
          (this.$refs['modal-erfolg'] as HTMLDivElement).show();
        }else if(newParams.modal == 'fehler') {
          //@ts-ignore
          (this.$refs['modal-fehler'] as HTMLDivElement).show();
        }
    }

  submit(event: SubmitEvent) {
    // grecaptcha.enterprise.ready(async () => {
    //   const token = await grecaptcha.enterprise.execute('6LdKxIkqAAAAANB2EmMRyAuhIPusBHl3EkiOb3kn', {action: 'LOGIN'});
    // });
    //@ts-ignore
    event.target.submit();
    //this.$emit('submit');
  }

  closeModal() {
    this.$router.replace('/');
  }
}
</script>

<style lang="scss" scoped>
  .row {
    gap: 10px;
    text-align: left;
  }
  .plakat {
    padding: 0;
    overflow: hidden;
    > img {
      width: 100%;
    }
  }
  .send-button {
    background: #572058;
    border-color: white;
  }

  ::v-deep .modal-content {
    background: #572058;
    color: white;

    .modal-header,
    .modal-footer {
      border: none;
    }
  }

  @media (min-width: 576px) {
    .row {
      flex-wrap: nowrap;
    }
    .info-text {
      background: #572058 url(../assets/Silvester-web.jpg) right no-repeat!important;
      background-size: auto 100%!important;
      padding: 0;

      .color-overlay {
        background: rgb(87,32,88);
        background: linear-gradient(90deg, rgba(87,32,88,1) 80%, rgba(2,0,36,0) 100%);
        width: 60%;
        height: 100%;
        padding: 15px;
      }
    }
  }

  @media (max-width: 575.98px) {
    .container {
      margin-bottom: 50px;
    }
  }
</style>
