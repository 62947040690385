import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Infos from '@/views/Infos.vue'
import Imprint from '@/views/Imprint.vue'
import DataSecure from '@/views/DataSecure.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Infos
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Imprint
  },
  {
    path: '/datenschutz',
    name: 'Datenschutzerkärung',
    component: DataSecure
  },
  {
    path: '/:modal',
    name: 'Home Modal',
    component: Infos
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
