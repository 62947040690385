import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
//import { VueRecaptchaPlugin } from 'vue-recaptcha'

Vue.config.productionTip = false
/*
app.use(VueRecaptchaPlugin, {
  v2SiteKey: 'YOUR_V2_SITEKEY_HERE',
  v3SiteKey: 'YOUR_V3_SITEKEY_HERE',
})
*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
