var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_vm._m(1),(false)?_c('div',{staticClass:"col-sm-4 bg-primary text-white rounded"},[_c('p',{staticClass:"pt-3"},[_vm._v(" Aktuell gibt es leider Probleme mit unserem Bestellformular, wir arbeiten aber dran und hoffen, dass es bald wieder für euch zur Verfügung steht. ")]),_vm._m(2)]):_vm._e(),(true)?_c('div',{staticClass:"col-sm-4 bg-primary text-white rounded"},[_c('form',{attrs:{"action":'https://www.form-to-email.com/api/s/' + _vm.prodID,"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])]):_vm._e()]),_c('div',[_c('b-modal',{ref:"modal-erfolg",attrs:{"ok-only":"","title":"Bestellung versandt"},on:{"ok":_vm.closeModal}},[_c('p',{staticClass:"my-4"},[_vm._v("Vielen Dank für die Bestellung der Karten")]),_c('p',[_vm._v("Wir melden uns alsbald mit dem weiteren Vorgehen zurück.")])]),_c('b-modal',{ref:"modal-fehler",attrs:{"ok-only":"","title":"Es tut uns leid"},on:{"ok":_vm.closeModal}},[_c('p',{staticClass:"my-4"},[_vm._v("Leider ist etwas schief gelaufen!")]),_c('p',[_vm._v("Bitte versuchen sie es erneut oder schreiben uns eine E-Mail an "),_c('b',[_vm._v("silvester(AT)kasseturm.de")])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-sm bg-primary text-white rounded d-block d-sm-none plakat"},[_c('img',{attrs:{"src":require("../assets/Silvester-web.jpg"),"alt":"Silvester Plakat"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-sm-8 bg-primary text-white rounded info-text"},[_c('div',{staticClass:"color-overlay"},[_c('p',[_vm._v("Wir laden ein zu der großen SILVESTERPARTY!")]),_c('p',[_c('b',[_vm._v("LIVE: Gents of Jazz")]),_c('br'),_vm._v("(Coverssongs mit einem gewissen Charme)"),_c('br'),_c('b',[_vm._v("& DISCO ")]),_vm._v("mit unseren Haus DJs"),_c('b',[_c('br')])]),_c('iframe',{attrs:{"type":"text/html","src":"https://www.youtube.com/embed/xNvqi7oWVBc?controls=0&disablekb=1&modestbranding=1&iv_load_policy=3","title":"Gents of Jazz Promo","frameborder":"0","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}}),_c('p',[_vm._v(" Im Eintrittspreis enthalten: "),_c('ul',[_c('li',[_vm._v("ein Fingerfood-Buffet")]),_c('li',[_vm._v("ein Begrüßungsgetränk")])]),_vm._v(" Außerdem im Angebot: "),_c('ul',[_c('li',[_vm._v("Kleiner Imbiss")]),_c('li',[_vm._v("Glühwein am Feuer")])])]),_c('p',[_c('b',[_vm._v("Kartenpreis im Vorverkauf: 32€")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Bitte schreibt uns an "),_c('b',[_vm._v("silvester(AT)kasseturm.de")]),_vm._v(" eine Email mit Namen, Adresse und die Anzahl der Karten, die ihr haben wollt und wir melden uns alsbald zurück. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group mt-2"},[_c('label',{attrs:{"for":"inputNumber"}},[_vm._v("Karten")]),_c('input',{staticClass:"form-control mr-2",attrs:{"type":"number","id":"inputNumber","value":"1","name":"anzahl","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputEmail4"}},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"inputEmail4","placeholder":"name@adresse.de","name":"email","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputName"}},[_vm._v("Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputName","placeholder":"Vor- & Nachname","name":"name","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputAddress"}},[_vm._v("Adresse")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputAddress","placeholder":"Straße + Hausnummer","name":"address","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputZip"}},[_vm._v("PLZ")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputZip","placeholder":"11111","name":"plz","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inputCity"}},[_vm._v("Stadt")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"inputCity","placeholder":"Stadt","name":"city","required":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"form-group mt-4"},[_c('button',{staticClass:"btn btn-primary btn-block send-button",attrs:{"type":"submit"}},[_vm._v("Karten bestellen")])])
}]

export { render, staticRenderFns }