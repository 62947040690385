<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <h2 @click="toStart" class="d-none d-sm-block clickable">Kasseturm</h2>
    <h1 @click="toStart" class="d-none d-sm-block clickable">Silvester Party</h1>
    <router-view/>
    <div class="footer">
      <router-link to="/impressum">Impressum</router-link>
      <router-link to="/datenschutz">Datenschutzerklärung</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class App extends Vue {
  toStart() {
    if(this.$route.path !== '/')    this.$router.push('/');
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  background-color: rgb(2,0,36);
}

#app {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(2,0,36);
  background: linear-gradient(200deg, rgba(2,0,36,1) 0%, rgba(169,44,132,1) 100%);
  height: 100vh;
  padding-top: 20px;
}

h1 {
  text-transform: uppercase;
  color: white;
  font-weight: 600;
  font-size: 7vw!important;
  @media (min-width: 768px) { font-size: 10vw; }
}
h2 {
  color: #54C3F0;
  text-transform: uppercase;
  font-size: 2vw!important;

}

.bg-primary {
  background: #572058!important;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.clickable:hover {
  cursor: pointer;
}

.footer {
  background: #572058;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 10px;

  a {
    color: white;
    padding: 0 10px;
  }
}

@media (max-width: 575.98px) {
 #app {
  padding: 20px;
  height: auto;
 }
}
</style>
